(function ($) {

  var $hamburger = $(".hamburger");
  var $body = $("body");
  var $html = $("html");

  $hamburger.on("click", function (e) {
    $hamburger.toggleClass("is-active");
    $body.toggleClass('has-nav-mobile-active');
    $html.toggleClass('is-fixed');
  });

/**
 * External links and empty hrefs
 */
$("a[href^='http']").not("a[href^='"+window.location.protocol+"\/\/"+window.location.hostname+"']").each(function(){
	$(this).attr("target", "_blank");
});


})(jQuery);
